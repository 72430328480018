import React, { useState } from 'react';
import Popup from './Popup'; // Importez le composant Popup

import './css/typography.css'; // Importez le fichier CSS
import './css/ExperienceSection.css';
import './css/layout.css'; // Importez le fichier CSS


// Importation des fichiers audio
import ventPoeme from './music/vent_poeme.mp3';
import fatherSon from './music/Father_.mp3';
import wishYouWereHere from './music/Wish_.mp3';
import putItThere from './music/Put_.mp3';
import Ballade from './music/Ballade.mp3';
import colours from './music/colours.mp3';
import planes from './music/planes.mp3';
import raya from './music/raya.mp3';
import summertime from './music/summertime.mp3';



const ExperienceColumn = ({ title, isOpen, toggleOpen, items, handleContentClick }) => (
  <div className="experience-column">
    <h2 onClick={toggleOpen} className="dropdown-title">
      {title}
    </h2>
    <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
      {items.map((item, index) => (
        <p
          key={index}
          className={item.type ? "clickable" : ""}
          onClick={item.type ? () => handleContentClick(item) : null}
        >
          {item.title}
        </p>
      ))}
    </div>
  </div>
);

const ExperienceSection = () => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null); // État unique pour suivre le menu ouvert

  const handleContentClick = (content) => {
    setSelectedContent(content);
  };

  const closePopup = () => {
    setSelectedContent(null);
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const studioItems = [
    { type: 'audio', title: "Summertime - Louise", audioPath: summertime, text: "Enregistrement - Mixage"  },
    { type: 'audio', title: "Je n’ai pas peur du vent", audioPath: ventPoeme, text: "Enregistrement voix" },
    { type: 'audio', title: "Father & Son - Serge Vilamajo", audioPath: fatherSon, text: "Mastering" },
    { type: 'audio', title: "Wish You Were Here (cover) - Serge Vilamajo", audioPath: wishYouWereHere, text: "Mastering" },
    { type: 'audio', title: "Put it There (cover) - Serge Vilamajo", audioPath: putItThere, text: "Mastering" },
    { type: 'audio', title: "Ballade Blues - Quentin Gouraud", audioPath: Ballade, text: "Enregistrement - Mixage" },
    { type: 'audio', title: "Colours in the Sky - Louise", audioPath: colours, text: "Enregistrement - Mixage" },
    { type: 'audio', title: "Raya - Casper Sanderson",  audioPath: raya, text: "Mixage"  },
    { type: 'audio', title: "Planes - Casper Sanderson",  audioPath: planes, text: "Mixage"  },
  ];

  const audiovisuelItems = [
    { type: 'image', title: "Bienvenue à Saint-Ailettes - Arno Polvent", imagePath: "/image/poulet.png", text: "Court-métrage de Fiction // 1e Assistant // Tentacules Production", link: "https://www.youtube.com/watch?v=tje1lRN7P1A&t=79s&ab_channel=ArnoPolvent" },
    { type: 'image', title: "Clip - Julia Florida", imagePath: "/image/barrios.webp", text: "Julia Florida de Barrios // joué, réalisé et enregistré par moi", link: "https://www.instagram.com/reel/DE75zW_O1XP/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA==" },
    { type: 'image', title: "Clip - An other night in Marseille", imagePath: "/image/jazz.webp", text: "Amadis De Gaula (voix et compo) // Moi à la réalisation et à l'enregistrement, mixage", link: "https://www.youtube.com/watch?v=tje1lRN7P1A&t=79s&ab_channel=ArnoPolvent" },
    { type: 'image', title: "Les Baigneuses - Anouk Barrère", imagePath: "/image/baigneuses.png", text: "Prise de Son, ambiances post-production", link: "https://fr.ulule.com/les-baigneuses/" },
    { type: 'video', title: "Capsule Sonore #07", videoUrl: "https://player.vimeo.com/video/546820161", text: "Court-Métrage d'animation onirique // Composition musicale et sonore // réalisation: Thomas Csaba" },
    { type: 'video', title: "Capsule Sonore #08", videoUrl: "https://player.vimeo.com/video/657810732", text: "Court-Métrage d'animation onirique // Composition musicale et sonore // Réalisation: Thomas Csaba" },
    { type: 'video', title: "Capsule Sonore #09", videoUrl: "https://player.vimeo.com/video/721948075", text: "Court-Métrage d'animation onirique // Composition musicale et sonore // Réalisation: Thomas Csaba" },
    { type: 'video', title: "9-4", videoUrl: "https://www.youtube.com/embed/nlMcYo7ZPo0", text: "Enregistrement live multi-piste" },
    { type: 'video', title: "American Boy", videoUrl: "https://www.youtube.com/embed/cKrbWY7-VMo", text: "Enregistrement studio clip" },
    { type: 'video', title: "My Lord Willoughby's Welcome Home", videoUrl: "https://www.youtube.com/embed/mbmWeG5VP04", text: "Enregistrement studio clip" },
    { type: 'video', title: "Canciones Populares", videoUrl: "https://www.youtube.com/embed/hoWQ3mQngPI", text: "Enregistrement studio live" },
    { type: 'video', title: "Arvö Pärt, Für Alina & Fratres", videoUrl: "https://www.youtube.com/embed/duiRN06cBSY", text: "Enregistrement studio live" },
    { type: 'video', title: "Kiua", videoUrl: "https://player.vimeo.com/video/517594841", text: "Composition et enregistrement studio clip" },
    { type: 'video', title: "Publicité Bourses Zellidja", videoUrl: "https://www.youtube.com/embed/NGlD_3wq7Nw", text: "Enregistrement Musique et Mixage" },
    { type: 'image', title: "En Maria - Alice de Lencquesaing", imagePath: "/image/maria.png", text: "Enregistrement de bruitages et de voix post-synchro",link: "https://lesfilmsdelautrecougar.com/2018/04/13/en-maria/" },

  ];
  const SpectacleItems = [
    {
      type: 'image',
      title: "Le Caveau des Oubliettes (Paris)",
      imagePath: "/image/spectacle/caveau2.png",
      text: "Ingénieur Son - Régisseur"
    },
    {
      type: 'image',
      title: "Les Amarres (Paris)",
      imagePath: "/image/spectacle/amarre3.png",
      text: "Ingénieur Son - Régisseur"
    },
    {
      type: 'image',
      title: "Le Cabaret Gandaia (Paris)",
      imagePath: "/image/spectacle/gandaia2.png",
      text: "Ingénieur du Son Itinérant"
    },
    {
      type: 'image',
      title: "La Péniche Antipode (Paris)",
      imagePath: "/image/spectacle/peniche1.png",
      text: "Ingénieur du Son"
    },
    {
      type: 'image',
      title: "Salle Gérard Philipe (Sainte Genevieve Des Bois)",
      imagePath: "/image/spectacle/philipe1.png",
      text: "Ingénieur du Son"
    },
    // Ajoutez d'autres items selon vos besoins
  ];

  return (
    <div className="scroll-section" id="experience">
      <h1 className="section-title">Expérience</h1>
      <div className="experience-section">
        <ExperienceColumn
          title="Studio"
          isOpen={openDropdown === 'studio'}
          toggleOpen={() => toggleDropdown('studio')}
          items={studioItems}
          handleContentClick={handleContentClick}
        />
        <ExperienceColumn
          title="Audiovisuel"
          isOpen={openDropdown === 'audiovisuel'}
          toggleOpen={() => toggleDropdown('audiovisuel')}
          items={audiovisuelItems}
          handleContentClick={handleContentClick}
        />
      <ExperienceColumn
        title="Spectacle"
        isOpen={openDropdown === 'Spectacle'}
        toggleOpen={() => toggleDropdown('Spectacle')}
        items={SpectacleItems}
        handleContentClick={handleContentClick}
      />
        
      </div>
      {selectedContent && (
        <Popup
        className="experience-popup"
        content={
          selectedContent.type === 'audio' ? (
            <>
              <h2>{selectedContent.title}</h2>
              <audio controls autoPlay>
                <source src={selectedContent.audioPath} type="audio/mpeg" />
                Votre navigateur ne prend pas en charge l'audio.
              </audio>
              {selectedContent.text && <small>{selectedContent.text}</small>}
            </>
          ) : selectedContent.type === 'video' ? (
            <>
              <iframe
                className="video-frame"
                src={selectedContent.videoUrl}
                title={selectedContent.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                width="100%"
                height="600"
              ></iframe>
              {selectedContent.text && <p>{selectedContent.text}</p>}
            </>
          ) : selectedContent.type === 'image' ? (
            <div>
              <a href={selectedContent.link} target="_blank" rel="noopener noreferrer">
                <img src={selectedContent.imagePath} alt={selectedContent.title} style={{ maxWidth: '100%' }} />
              </a>
              <p>{selectedContent.text}</p>
            </div>
          ) : null
        }
        closePopup={closePopup}
      />
    )}
  </div>
);
};

export default ExperienceSection;